import styled from "styled-components";

const Button = ({ children, onClick, disabled, padding = "14px 10px" }) => {
  return (
    <Container onClick={onClick} disabled={disabled} padding={padding}>
      <ButtonText>{children}</ButtonText>
    </Container>
  );
};

export default Button;

const Container = styled.button`
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding: ${(props) => props.padding};
  font-weight: 500;
  border: ${(props) => (props.disabled ? "1px solid #929292" : "none")};
  width: 100%;
  background-color: ${(props) => (props.disabled ? "#f5f5f5" : "#2b3e76")};
  cursor: pointer;
  color: ${(props) => (props.disabled ? "#929292" : "#fff")}; ;
`;

const ButtonText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 18px;
`;
