import Web3 from "web3";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const BlockHeight = () => {
  const [currentBlockNumber, setCurrentBlockNumber] = useState("1");
  const [startBlockHeight] = useState(11976144);

  const getBlockNumber = async () => {
    const web3 = new Web3(
      new Web3.providers.HttpProvider(process.env.REACT_APP_RPC_URL),
    );
    let block = await web3.eth.getBlockNumber();
    setCurrentBlockNumber(block);
  };
  getBlockNumber();

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useInterval(() => {
    getBlockNumber();
  }, 2000);

  return (
    <Container>
      <div>
        <span>
          Block Height : <span>#{startBlockHeight}</span>
        </span>
      </div>
      <div>
        <span>
          Current Height : <span>#{currentBlockNumber}</span>
        </span>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-top: 60px;
  margin-bottom: 60px;

  div {
    display: inline-block;
    width: 50%;
    text-align: center;

    span {
      font-weight: 700;
      font-size: 16px;
      color: #929292;
      span {
        font-size: 18px;
        color: #2b3e76;
      }
    }
  }
`;

export default BlockHeight;
