import { useEffect, useState } from "react";
import styled from "styled-components";

const AmountBar = ({ amount, totalAmount }) => {
  const [barPer, setBarPer] = useState();

  useEffect(() => {
    setBarPer(`${(amount / totalAmount) * 100}%`);
  }, [amount, totalAmount]);

  return (
    <>
      <BarContainer>
        <FillBar width={barPer} />
        <MaxBar />
      </BarContainer>
      <RemainingAmount>
        <RemainingAmountText>Remaining Amount</RemainingAmountText>
        <RemainingAmountText>
          {amount} / {totalAmount}
        </RemainingAmountText>
      </RemainingAmount>
    </>
  );
};

export default AmountBar;

const BarContainer = styled.div`
  position: relative;
  margin-bottom: 75px;
`;

const MaxBar = styled.div`
  position: absolute;
  width: 100%;
  height: 16px;
  background-color: #f5f5f5;
  border-radius: 25px;
`;

const FillBar = styled.div`
  position: absolute;
  width: ${(props) => props.width};
  height: 16px;
  background-color: #2b3e76;
  border-radius: 25px;
  z-index: 10;
`;

const RemainingAmount = styled.div`
  justify-content: space-between;
  display: flex;
`;

const RemainingAmountText = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #929292;
`;
