import { useState, useEffect, useRef } from "react";
import { useMoralis, useNativeBalance, useMoralisWeb3Api } from "react-moralis";

function NativeBalance(props) {
  const { account, chainId, isAuthenticated, web3 } = useMoralis();
  const { nativeToken } = useNativeBalance(props);
  const moralisApi = useMoralisWeb3Api();

  const getBalances = async () => {
    return await moralisApi.account.getNativeBalance({
      address: account,
      chain: chainId,
      to_block: await web3.getBlockNumber(),
    });
  };

  const [balance, setBalance] = useState({
    balance: undefined,
    formatted: undefined,
  });

  const updateNativeBalance = async () => {
    let getting_balance = (await getBalances()).balance;

    if (!getting_balance) return;

    getting_balance = getting_balance / 10 ** 18;

    setBalance({
      balance: getting_balance,
      formatted: `${Math.floor(getting_balance * 10000) / 10000} ${
        nativeToken.symbol
      }`,
    });
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useEffect(() => updateNativeBalance(), []);

  useInterval(async () => {
    updateNativeBalance();
  }, 3000);

  if (!account || !isAuthenticated) return null;

  return (
    <div style={{ textAlign: "center", whiteSpace: "nowrap" }}>
      {balance.formatted}
    </div>
  );
}

export default NativeBalance;
