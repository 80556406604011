import React, { useState, useEffect, useRef } from "react";
import { useMoralis } from "react-moralis";

function Ramper() {
  const [ramper, setRamper] = useState();
  const { Moralis } = useMoralis();

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const ramperInit = () => {
    if (!Moralis?.["Plugins"]?.["fiat"]) return null;
    async function initPlugin() {
      Moralis.Plugins.fiat.buy({}, { disableTriggers: true }).then((data) => {
        console.log("init", data);
        setRamper(data.data);
      });
    }
    initPlugin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    ramperInit();
  }, [Moralis.Plugins]);

  useInterval(() => {
    if (ramper) return;

    ramperInit();
  }, 2000);

  const ramperURI = `${ramper}`;

  let tempRamperURI = ramperURI;

  tempRamperURI = ramperURI.slice(
    0,
    ramperURI.indexOf("apiKey=") + "apiKey=".length,
  );

  tempRamperURI += ramperURI.slice(ramperURI.indexOf("pk_"));

  return (
    <>
      <iframe
        src={tempRamperURI}
        title="ramper"
        frameBorder="no"
        allow="accelerometer; autoplay; camera; gyroscope; payment;"
        style={{
          width: "420px",
          height: "625px",
          boxShadow: "0 0.5rem 1.2rem rgb(189 197 209 / 20%)",
          border: "1px solid #e7eaf3",
          borderRadius: "1rem",
          backgroundColor: "white",
        }}
      />
    </>
  );
}

export default Ramper;
