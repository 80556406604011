import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import { useMoralis } from "react-moralis";
import { ThirdwebSDK } from "@thirdweb-dev/sdk";
import Counter from "./Counter";
import AmountBar from "./AmountBar";
import BlockHeight from "./BlockHeight";
import styled from "styled-components";
import Button from "components/Common/Button";
import Card from "components/Common/Card";

function Mint() {
  const nftdropsKey = process.env.REACT_APP_THIRD_WEB;
  const { account, Moralis } = useMoralis();
  const [loading, setLoading] = useState(true);
  const [quantity, setQuantity] = useState(1);
  const [totalAmount, setTotalAmount] = useState(0);
  const [amount, setAmount] = useState(0);

  // 첫 실행시 수량 가져오기
  useEffect(() => {
    getNftAmount();
  }, []);

  // 수량 가져오기
  const getNftAmount = async () => {
    const provider = new ethers.providers.JsonRpcProvider(
      process.env.REACT_APP_RPC_URL,
    );

    const sdk = new ThirdwebSDK(provider);
    const contract = await sdk.getNFTDrop(nftdropsKey);

    try {
      const claimedNFTCount = await contract.totalClaimedSupply();
      const unclaimedNFTCount = await contract.totalUnclaimedSupply();
      setTotalAmount(parseInt(claimedNFTCount) + parseInt(unclaimedNFTCount));
      setAmount(parseInt(unclaimedNFTCount));
    } catch (err) {
      console.log(err);
    }
  };

  // minting
  const minting = async () => {
    setLoading(false);
    const provider = await Moralis.enableWeb3();

    const sdk = new ThirdwebSDK(provider.getSigner());
    const contract = await sdk.getNFTDrop(nftdropsKey);

    const limited = await contract.getOwned(account);

    if (account && contract && limited.length < 3 && quantity < 4) {
      try {
        const tx = await contract.claimTo(account, quantity);
        const receipt = tx.receipt; // the transaction receipt
        const claimedTokenId = tx.id; // the id of the NFT claimed

        console.log("receipt", receipt);
        console.log("claimedTokenId", claimedTokenId);
        getNftAmount();
        alert("Minting Success");
      } catch (err) {
        alert("Minting fail");
        console.log(err);
      }
    } else if (limited.length > 3 || quantity > 3) {
      alert("Already has too much NFT");
    }
    setLoading(true);
  };

  return (
    <>
      <Card>
        <Wrapper>
          <LeftWrapper>
            <NFTImg src="./logo.png" alt="mint photo" />
          </LeftWrapper>
          <RightWrapper>
            <BlockHeight />
            <AmountBar totalAmount={totalAmount} amount={amount} />
            <Counter quantity={quantity} setQuantity={setQuantity} />

            {!account ? (
              <Button
                onClick={() => {
                  alert("Please connect wallet first");
                }}
              >
                Please connect wallet first
              </Button>
            ) : loading ? (
              <Button
                onClick={async () => {
                  minting();
                }}
              >
                Mint
              </Button>
            ) : (
              <Button disabled>Mint Loading...</Button>
            )}
            <AbleText>Able to buy 3</AbleText>
          </RightWrapper>
        </Wrapper>
      </Card>
    </>
  );
}

const Wrapper = styled.div`
  min-width: 360px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const LeftWrapper = styled.div`
  flex: auto;
  width: 436px;
`;
const RightWrapper = styled.div`
  flex: auto;
  width: 451px;
`;

const NFTImg = styled.img`
  width: 436px;
  margin: 0 auto;
`;

const AbleText = styled.p`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: #929292;
`;

export default Mint;
