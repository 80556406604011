import React, { useEffect, useState } from "react";
import Button from "components/Common/Button";
import Card from "components/Common/Card";
import { useMoralis } from "react-moralis";
import axios from "axios";

function Faucet() {
  const [transactions, setTransactions] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const { account } = useMoralis();

  useEffect(() => {
    if (!account) {
      setTransactions("");
    }
  }, [account]);

  const getFaucet = () => {
    setIsLoading(false);
    if (account) {
      try {
        axios
          .post("https://namu-rinkeby-faucet.herokuapp.com/faucet", {
            address: account,
          })
          .then((response) => {
            setTransactions(response.data.txid);
            setIsLoading(true);
            console.log("OK", response.data.txid);
          })
          .catch((error) => {
            setIsLoading(true);
            console.log("err", error);
          });
      } catch {
        setIsLoading(true);
        console.log("error");
      }
    } else {
      setIsLoading(true);
      alert("Please connect your wallet first");
    }
  };

  return (
    <div>
      <Card maxWidth={"660px"} height={"400px"} minWidth="500px">
        <h3
          style={{
            fontSize: "30px",
            textAlign: "center",
            padding: "20px",
          }}
        >
          Faucet
        </h3>
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: "5px",
            marginBottom: "30px",
          }}
        >
          <div
            style={{
              marginTop: "60px",
              width: "100%",
              marginBottom: "40px",
              minWidth: "350px",
            }}
          >
            {isLoading ? (
              <Button
                padding={"14px 60px"}
                onClick={() => {
                  getFaucet();
                }}
              >
                {account
                  ? "Request ether from faucet"
                  : "Please connect your wallet first"}
              </Button>
            ) : (
              <Button disabled>Loading...</Button>
            )}
          </div>
        </div>
        {transactions ? (
          <>
            <h3>transactions</h3>
            <hr style={{ border: "0.5px solid #e1e1e1" }} />

            <a href={`https://rinkeby.etherscan.io/tx/${transactions}`}>
              <p
                style={{
                  cursor: "pointer",
                  fontSize: "15px",
                  color: "#1890ff",
                  display: "inline-block",
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {transactions}
              </p>
            </a>
          </>
        ) : (
          <></>
        )}
      </Card>
    </div>
  );
}

export default Faucet;
