import styled from "styled-components";

const Counter = ({ quantity, setQuantity }) => {
  const quantityHandler = (value) => {
    if (value === "minus" && quantity > 1) {
      setQuantity(quantity - 1);
    } else if (value === "plus" && quantity < 3) {
      setQuantity(quantity + 1);
    }
  };

  return (
    <CounterContainer>
      <MinusButton
        onClick={() => {
          quantityHandler("minus");
        }}
      >
        <img src="/minus.png" alt="minus" />
      </MinusButton>
      <QuantityBox>
        <p>{quantity}</p>
      </QuantityBox>
      <PlusButton
        onClick={() => {
          quantityHandler("plus");
        }}
      >
        <img src="/plus.png" alt="plus" />
      </PlusButton>
    </CounterContainer>
  );
};

export default Counter;

const CounterContainer = styled.div`
  display: flex;
  margin: 45px 0;
`;

const MinusButton = styled.div`
  width: 60px;
  height: 60px;
  background-color: #d6d5d5;
  border-radius: 100%;
  cursor: pointer;
  position: relative;

  :active {
    background-color: #2b3e76;
  }

  img {
    position: absolute;
    width: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`;

const PlusButton = styled.div`
  width: 60px;
  height: 60px;
  background-color: #d6d5d5;
  border-radius: 100%;
  cursor: pointer;
  position: relative;

  :active {
    background-color: #2b3e76;
  }

  img {
    position: absolute;
    width: 25px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
`;

const QuantityBox = styled.div`
  width: calc(100% - 120px);
  height: 60px;
  background-color: #f5f5f5;
  border-radius: 30px;
  margin: 0 20px;

  p {
    text-align: center;
    line-height: 60px;
    color: #2b3e76;
  }
`;
