import styled from "styled-components";

const Card = ({
  children,
  width = "100%",
  height = "100%",
  minWidth = "360px",
  maxWidth = "1024px",
  padding = "20px 60px",
}) => {
  return (
    <Container
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
      padding={padding}
      height={height}
    >
      {children}
    </Container>
  );
};

export default Card;

const Container = styled.div`
  box-shadow: 0px 4px 44px 10px rgba(206, 203, 203, 0.25);
  border: 1px solid #e2e2e2;
  border-radius: 30px;
  max-width: ${(props) => props.maxWidth};
  min-width: ${(props) => props.minWidth};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  font-size: 16px;
  font-weight: 500;
  padding: ${(props) => props.padding};
  background-color: #fff;
`;
